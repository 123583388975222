import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import styled from "styled-components"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import SliderSwiper from "../components/sliderSwiper/sliderSwiper";
import DividerBorder from "../components/dividerBorder";
import MultimediaText from "../components/multimediaText";
import MotionCertificaMVber from "../components/motion/certificadoMvber";
import MotionCertificaMVberMobile from "../components/motion/certificadoMvberMobile";
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"


const IndexPage = ({ data }) => {

  return (
    <>
      <SEO title="Homepage" image={data.indexJson.intro.slide[0].background.childImageSharp.fluid.src}/>
      <Layout home>
        {useBreakpoint().mobile ? (
          <IndexPageStyled>
            <HeaderMobile data={data.globalJson.header} />

            <SliderSwiper data={data.indexJson.introMobile.slide} mobile home top oneSlideBorder/>

            <DividerBorder text={data.indexJson.divideBorder} inverse={false} mobile={true} />

            <MotionCertificaMVberMobile data={data.indexJson.certificate} />

            <DividerBorder text={false} inverse={true} mobile={true} />

            <div className="containers-multimedia-text">
              {data.indexJson.multimediaText.map((item, index) => (
                <div key={'multimedia-text-' + index} className="container-multimedia-text">
                  <MultimediaText data={item} inverse={false} video={true} justify/>
                  {(index < (data.indexJson.multimediaText.length - 1)) && <hr />}
                </div>
              ))}
            </div>

            <FooterMobile data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />

          </IndexPageStyled>
        ) : (
          <IndexPageStyled>
            <Header data={data.globalJson.header} />

            <SliderSwiper data={data.indexJson.intro.slide} home top />

            <DividerBorder text={data.indexJson.divideBorder} inverse={false} mobile={false} />

            <MotionCertificaMVber data={data.indexJson.certificate} />

            <DividerBorder text={false} inverse={true} />

            <div className="containers-multimedia-text">
              {data.indexJson.multimediaText.map((item, index) => (
                <div key={'multimedia-text-' + index} className="container-multimedia-text">
                  <MultimediaText data={item} inverse={index % 2 ? true : false} video={true} slide={false} justify/>
                  {(index < (data.indexJson.multimediaText.length - 1)) && <hr />}
                </div>
              ))}
            </div>
            <Footer data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
          </IndexPageStyled>
        )}
      </Layout>
    </>
  )
}

export default IndexPage

export const Json = graphql`
  query index {
    indexJson {
      divideBorder{
        title
        subtitle
      }
      intro {
        slide{
          title
          background {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      introMobile {
        slide{
          title
          background {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      certificate{
        title
        description
        btnText
        btnLink
        btnClass
        imgCertificate{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      multimediaText{
        video
        title
        description
        btnText
        btnLink
        btnClass
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
       
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      floatButtons{
        btn
        btnLink
      }
    }
  }
`

const IndexPageStyled = styled.div`
    .containers-multimedia-text{
      padding: 15vh 5vw 10vh 5vw;
    }
    hr{
      margin-top: 5vh;
      margin-bottom: 5vh;
      height: 1px;
      background-color: #5A5A5A;
      border: none;
    }
`
