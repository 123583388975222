import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import styled from "styled-components"
import { Buttons } from '../buttons'
import font from "../../fonts/fonts.module.scss"

import { InView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import Img from "gatsby-image"

const MotionCertificaMVber = ({ data }) => {

    const controls = useAnimation();

    const [inView, setInView] = useState(false);

    const boxVariants = {
        hidden: {
            x: "-150vw",
            transition: {
                duration: 0.5,
            }
        },
        visible: {
            x: "-50vw",
            transition: {
                duration: 1,

            }
        }
    }

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);


    return (
        <MotionCertificaMVberStyled>
            <InView as="div" className="container-car" threshold={0.15} onChange={inViewDiv => {
                setInView(inViewDiv)
            }}>
                <motion.div className="Box" initial="hidden" animate={controls} variants={boxVariants}>
                    <img className="car" src="https://stand.hcsperformance.pt/wp/wp-content/uploads/2023/07/mini-john-cooper-gp.png" alt="white_car" width="1000" height="500" />
                </motion.div>
            </InView>
            <div className="container-info">
                <div className="container-certificate-text">
                    <h1 className={font.oL + " intro-title"}>
                        {data.title}
                    </h1>
                    <p className={font.oL + " intro-text"}>
                        {data.description}
                    </p>
                    <div className={font.oL + ' p-relative container-btn'} style={{ paddingTop: "2vh" }}>
                        <Buttons text={data.btnText} actionFunction={() => { navigate(data.btnLink) }} btnClass={data.btnClass} />
                    </div>
               
                </div>
                <div className="container-certificate-img">
                    <Img 
                        fluid={data.imgCertificate.childImageSharp.fluid}
                        alt={data.title}
                    />
                </div>
            </div>
        </MotionCertificaMVberStyled>
    )
}

export default MotionCertificaMVber

const MotionCertificaMVberStyled = styled.div`
height: 80vh;
display: grid;
margin-top: -5vh;
grid-template-columns: 50% 50%;
align-items: center;
background-color: #ffffff;
background-repeat: no-repeat;
background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1074.28' height='1614.981' viewBox='0 0 1074.28 1614.981'%3e%3cdefs%3e%3clinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3e%3cstop offset='0' stop-color='%23fffdfd'/%3e%3cstop offset='1' stop-color='%23e4e4e4'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath id='Caminho_2097' data-name='Caminho 2097' d='M-4279.905,881.981-3205.625-733h-1074.28V881.981Z' transform='translate(4279.905 733)' fill='url(%23linear-gradient)'/%3e%3c/svg%3e");
background-position: -25vw top;

.container-car{
    margin-top: -10vh;
}
.car{
    height: 40vw;
    width: auto;
}
.container-info{
    display: grid;
    grid-template-columns: 50% 50%;
}
.uppercase{
    text-transform: uppercase;
}
.intro-title{
    margin: 0 0 0.5em;
    text-transform: uppercase;
    color: #0f0f0f;
}
.intro-text{
    margin: 4vh auto 4vh;
    color: #0f0f0f;
}
.container-certificate-img{
    height: 20vh;
    width: 50%;
    .gatsby-image-wrapper, img{
        height: 100%;
        object-fit: contain !important;
        width: auto;
    }
}

.container-btn{
    width: 12vw;
    float: left;
}

@media (max-width: 1024px){
    background-position: 345% top;
    .car{
        height: 48vw;
    }
    .container-btn{
        width: 38vw;
    }
}


`