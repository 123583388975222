import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import { InView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import Img from "gatsby-image"
import { Buttons } from '../buttons'

const MotionCertificaMVberMobile = ({ data }) => {

    const controls = useAnimation();

    const [inView, setInView] = useState(false);

    const boxVariants = {
        hidden: {
            x: "-170vw",
            transition: {
                duration: 0.7,
            }
        },
        visible: {
            x: "-110vw",
            transition: {
                duration: 0.7,
            }
        }
    }

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <MotionCertificaMVberMobileStyled>
            
            <div className="container-info">
                <div className="container-certificate-text">
                    <h1 className={font.oEL + " intro-title"}>
                        {data.title}
                    </h1>
                    <p className={font.oEL + " intro-text justify"}>
                        {data.description}
                    </p>
                    <div className={font.oL + ' p-relative container-btn'} style={{ paddingTop: "2vh" }} id={data.btnClass}>
                        <Buttons text={data.btnText} actionFunction={() => { navigate(data.btnLink) }} btnClass={data.btnClass} />
                    </div>
                </div>
                <div className="container-certificate-img">
                    <Img
                        fluid={data.imgCertificate.childImageSharp.fluid}
                        alt={data.title}
                    />
                </div>
            </div>
            <InView as="div" className="container-car" threshold={0.7} onChange={inViewDiv => {
                setInView(inViewDiv)
            }}>
                <motion.div className="Box" initial="hidden" animate={controls} variants={boxVariants}>
                    <img className="car" src="https://stand.hcsperformance.pt/wp/wp-content/uploads/2023/07/mini-john-cooper-gp.png" alt="white_car" width="1000" height="500" />
                </motion.div>
            </InView>
        </MotionCertificaMVberMobileStyled>
    )
}

export default MotionCertificaMVberMobile

const MotionCertificaMVberMobileStyled = styled.div`
height: 80vh;
align-items: center;
background-color: #ffffff;
background-repeat: no-repeat;
background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1074.28' height='1614.981' viewBox='0 0 1074.28 1614.981'%3e%3cdefs%3e%3clinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3e%3cstop offset='0' stop-color='%23fffdfd'/%3e%3cstop offset='1' stop-color='%23e4e4e4'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath id='Caminho_2097' data-name='Caminho 2097' d='M-4279.905,881.981-3205.625-733h-1074.28V881.981Z' transform='translate(4279.905 733)' fill='url(%23linear-gradient)'/%3e%3c/svg%3e");
padding-top: 5vh;
background-position: top right;
position: relative;
overflow: hidden;


.justify{
    text-align: justify !important;
}

.container-btn{
    width: 38vw;
    float: left;
}
.container-car{
    display: grid;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    overflow: hidden;
    z-index: 99;
    position: relative;
}
.car{
    height: auto;
    width: calc(100% - -55vh);
    height: auto;
    max-width: fit-content;
}
.container-info{
    display: grid;
    grid-template-columns: 65% 30%;
    padding-left: 5vh;
    padding-right: 5vh;
    column-gap: 5%;
}
.uppercase{
    text-transform: uppercase;
}
.intro-title{
    margin: 0 0 0.5em;
    text-transform: uppercase;
    color: #0f0f0f;
}
.intro-text{
    margin: 2vh auto 2vh;
    color: #0f0f0f;
}
.container-certificate-img{
    height: 100%;
    width: 100%;
    .gatsby-image-wrapper, img{
        height: 100%;
        object-fit: contain !important;
        width: auto;
    }
}
`